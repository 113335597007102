<script lang="ts" setup>
  const clinicStore = useClinicsStore();
  const showModalGallery = ref(false);
</script>

<template>
  <div class="clinic-awards-wrapper">
    <div class="clinic-awards scroll-container">
      <div class="scrolling-block d-flex">
        <div
          v-for="(item, key) in clinicStore.currentClinic?.awards"
          :key="key"
          class="clinic-awards__item pointer"
          @click="showModalGallery = true"
        >
          <div
            class="clinic-awards__logo"
          >
            <IconLogoNapopravku v-if="item.company === 'nap'" style="width: 35px; height: 35px;" :filled="true" />
            <IconLogoProdoctorov v-if="item.company === 'prodoc'" style="width: 35px; height: 35px;" :filled="true" />
            <IconLogoYandex v-if="item.company === 'ya'" style="width: 35px; height: 35px;" :filled="true" />
            <IconLogoSber v-if="item.company === 'sber'" style="width: 35px; height: 35px;" :filled="true" />
          </div>
          <div class="clinic-awards__text">
            <div class="text-semibold">
              {{ item.title }}
            </div>
            <div class="annotation">
              {{ item.year }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Teleport v-if="showModalGallery" to="body">
      <div class="modal">
        <LazyClinicPagePhotoCarousel :photos="clinicStore.currentClinic?.awards" />
      </div>
      <div class="overlay" @click.stop="showModalGallery = false"></div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.clinic-awards-wrapper {
  margin-top: 24px;
  @media (max-width: 400px) {
    margin-top: 0;
  }
}
.modal {
    z-index: 102;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 713px;
    height: 435px;
    @media (max-width: 1023px) {
      width: 80%;
      height: 0;
      display: flex;
      align-items: center;
    }
    @media (max-width: 600px) {
      width: 100%;
      height: 0;
      display: flex;
      align-items: center;
    }
  }
  .overlay {
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
.clinic-awards {
  &__item {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    background-color: $fields-back-color;
    height: 55px;
    padding: 0 16px 0 10px;
    min-width: 175px;
    border-radius: 55px;
    flex-direction: row;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  &__logo {
    display: flex;
    justify-content: center;
  }
  &__text {
    margin-left: 8px;
    line-height: 1.2em;
  }
}
.zoom-gallery {
  @media (max-width: $screen-md) {
    display: flex;
    align-items: center;
  }
  &__wrapper {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    @media (max-width: $screen-md) {
      background-color: rgba(0,0,0,.4);
    }
  }
  &__close-button {
    display: none !important;
    @media (max-width: $screen-md) {
      display: inline !important;
      position: absolute !important;
      filter: brightness(100);
      top: 10px;
      right: 11px;
      z-index: 100;
    }
  }
  @media (max-width: $screen-md) {

  }
  &.v-dialog {
    box-shadow: none;
    @media (max-width: $screen-md) {
      background: rgba(0,0,0,.4);
      margin: 0;
      border-radius: 0;
    }

  }
  &__images-wrap {

  }
  &__main-photo {
    display: flex;
    height: 100%;
    background-color: #ffffff78;
    & img {
      width: 100%;
    }
  }
  & .v-window__container {
    @media (max-width: $screen-md) {
      border-radius: 0;
    }
  }
  & .doctor__top__gallery__back_arrow, & .doctor__top__gallery__next_arrow {
    @media (max-width: $screen-md) {
      width: 24px;
      height: 24px;
      & .icons {
        height: 10px;
      }
    }

  }

  @media (max-width: $screen-sm) {
    & .doctor__top__gallery__back_arrow {
      left: 3px;
    }
    & .doctor__top__gallery__next_arrow {
      right: 3px;
    }
  }

}
</style>
