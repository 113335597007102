<script lang="ts" setup>
  import AwardsGallery from './AwardsGallery.vue';
  import EcButton from '~/UI/Buttons/EcButton.vue';
  const { currentClinic } = storeToRefs(useClinicsStore());
  const pageInfoStore = usePageInfoStore();
  const showModal = ref(false);
  const showModalGallery = ref(false);
  const showModalMap = ref(false);

  const images = computed(() => {
    if (!currentClinic.value || !currentClinic.value.photos) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (currentClinic.value.photos as any)['0x0'] as { image: string }[];
  });

  const parseMap = (rawMap: string|null|undefined) => {
    if (!rawMap) {
      return '';
    }
    const regex = /src="([^"]+)"/;
    const match = rawMap.match(regex);

    if (match && match[1]) {
      return match[1];
    }
    return '';
  };

  const scrollContainer = ref<HTMLElement | null>(null);

  const canScrollPrev = ref(false);
  const canScrollNext = ref(true);

  const updateScrollButtons = () => {
    const container = scrollContainer.value;
    if (container) {
      canScrollPrev.value = container.scrollLeft > 0;
      canScrollNext.value = container.scrollLeft + container.clientWidth < container.scrollWidth;
    }
  };

  const scrollPrev = () => {
    if (scrollContainer.value) {
      scrollContainer.value.scrollBy({ left: -scrollContainer.value.clientWidth, behavior: 'smooth' });
    }
  };

  const scrollNext = () => {
    if (scrollContainer.value) {
      scrollContainer.value.scrollBy({ left: scrollContainer.value.clientWidth, behavior: 'smooth' });
    }
  };

  onMounted(() => {
    if (scrollContainer.value) {
      scrollContainer.value.addEventListener('scroll', updateScrollButtons);
    }
  });
  onBeforeUnmount(() => {
    if (scrollContainer.value) {
      scrollContainer.value.removeEventListener('scroll', updateScrollButtons);
    }
  });
</script>

<template>
  <div class="info-wrapper">
    <div class="clinic-info">
      <ClinicPageInfo
        :vrachi-page="true"
        class="clinic-info-area"
        :circle-color="String(currentClinic?.node_color)"
        :text-main="currentClinic?.node_address ?? ''"
        :text-secondary="clinicGetTextSecondary(currentClinic?.way, currentClinic?.way_time)"
        :text-address="currentClinic?.address ?? ''"
        :pandus="Boolean(currentClinic?.clinic_pandus)"
        :parking="currentClinic?.clinic_parking"
        :working-time="currentClinic?.clinic_hours"
      />
      <div class="clinic-info-gallery relative">
        <div style="position: relative;">
          <div class="clinic-gallery-wrapper">
            <div ref="scrollContainer" class="photos-container">
              <div class="clinic-gallery-item">
                <div class="relative" @click="showModalMap = true">
                  <div class="white-circle clinic-gallery-map-button">
                    <IconMapOpen style="width: 20px;height: 20px;" />
                  </div>
                  <img loading="lazy" :src="`https://eastclinic.ru/assets/images/maps/${currentClinic?.url}-map.jpg`">
                </div>
              </div>
              <div
                v-for="(item, key) in images"
                :key="key"
                class="clinic-gallery-item"
                @click="showModalGallery = true"
              >
                <img loading="lazy" :src="item.image" alt="">
              </div>
            </div>
          </div>
          <div class="clinic-gallery-nav">
            <div v-if="canScrollPrev" class="button clinic-gallery-nav-prev" @click="scrollPrev">
              <IconBack filled />
            </div>
            <div v-if="canScrollNext" class="button clinic-gallery-nav-next" @click="scrollNext">
              <IconBack style="transform: rotate(180deg)" filled />
            </div>
          </div>
        </div>
        <AwardsGallery />
      </div>
    </div>
    <div class="d-flex">
      <EcButton style="max-width: 260px;" class="primary medium full-width clinic-button" @click="showModal = true">
        Как добраться
      </EcButton>
      <NuxtLink target="_blank" external :href="pageInfoStore.getWhatsAppPhone" rel="nofollow" class="whats-app-container">
        <IconWhatsApp filled :font-controlled="false" class="icon-whats-app" />
      </NuxtLink>
      <NuxtLink :href="pageInfoStore.getLinkPhone" target="_blank" external class="whats-app-container">
        <IconPhoneHeader :font-controlled="false" filled class="icon-whats-app" />
      </NuxtLink>
    </div>
    <Teleport v-if="showModalGallery" to="body">
      <div class="modal">
        <LazyClinicPagePhotoCarousel :photos="images" />
      </div>
      <div class="overlay" @click.stop="showModalGallery = false"></div>
    </Teleport>
    <Teleport v-if="showModalMap" to="body">
      <div class="overlay" @click.stop="showModalMap = false">
        <div class="map-container">
          <div class="map">
            <component
              :is="'script'"
              :src="parseMap(currentClinic?.ymap)"
              async
            />
            <div class="close-map-button">
              Закрыть
            </div>
          </div>
        </div>
      </div>
    </Teleport>
    <ClinicPageModalLocation
      v-model="showModal"
      :modal-content="currentClinic?.info_block ?? []"
      :google-link="currentClinic?.google_link ?? ''"
      :lat="currentClinic?.lat ?? 0"
      :lon="currentClinic?.lon ?? 0"
    />
  </div>
</template>

<style scoped lang="scss">
  /* for ClinicPageInfo */
  /* .clinic-info-container {
    width: 100%;
  } */
  .clinic-info {
    display: grid;
    grid-template-columns: calc(50% - 25px) 50%;
    grid-template-areas: "clinic-info clinic-info-gallery"
                          "clinic-info clinic-info-gallery";
    @media (max-width: 1023px) {
      grid-template-columns: 100%;
      grid-template-areas: "clinic-info-gallery"
                          "clinic-info";
    }
    column-gap: 20px;
    @media (max-width: $screen-xs) {
      padding: 0 5px;
    }
  }
  .clinic-info-area {
    grid-area: clinic-info;
    @media (max-width: 1023px) {
      margin-bottom: 24px;
    }
  }
  .clinic-info-gallery {
    grid-area: clinic-info-gallery;
  }
  .info-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: start;
      gap: 40px;
      margin-top: 24px;
    }
  }
  .clinic-info-container {
    border: none
  }
  .clinic-button {
    padding: 0 20px;
    @media (max-width: 450px) {
      width: 50%;
      min-width: 170px;
    }
  }
  .clinic-gallery-item {
    width: 210px;
      height: 140px;
      border-radius: 15px;
      overflow: hidden;
      flex-shrink: 0;
      cursor: pointer;
      @media (max-width: 1023px) {
        width: 140px;
        height: 120px;
      }
      &:not(:last-child) {
        margin-right: 4px;
      }
      & img {
        width: 100%;
        @media (max-width: 1023px) {
          width: 130%;
        }
      }
  }
  .clinic-gallery-map-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .photos-container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
  }
  .clinic-gallery-wrapper {
    overflow-x: hidden;
    overflow-y: visible;
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 1023px) {
      margin: 24px 0 32px;
    }
    @media (max-width: 400px) {
      margin: 8px 0 16px;
    }
  }
  /* .clinic-gallery-nav {
    position:absolute;
  } */
  .clinic-gallery-nav-prev, .clinic-gallery-nav-next {
    cursor: pointer;
    background: #fff;
    width: 48px;
    height: 48px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    box-shadow: 0px 2px 8px 0px rgba(33, 35, 38, 0.15);
  }
  .clinic-gallery-nav-prev {
    left: -14px;
    top: calc(50% - 22px);
    & svg {
      position: relative;
      left: -1px;
    }
  }
  .clinic-gallery-nav-next {
    right: -14px;
    top: calc(50% - 22px);
  }

.whats-app-container {
  padding: 12px;
  border-radius: 16px;
  background-color: #F5F6F9;
  height: 44px;
  margin-left: 8px;
  display: inline-block;
  cursor: pointer;
  /* @media (max-width: 1023px) {
    padding: 8px;
    background-color: transparent;
    height: 40px;
  } */
  @media (min-width: 1023px) {
    &:hover {
      background-color: #EDEEF3;
    }
  }
}
.icon-whats-app {
  width: 24px;
  height: 22px;
  margin: 0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(0, 0, 0, 0.5);
}
.map-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    transform: none;
    left: 0;
    top: 0;
  }
}
.map {
  position: relative;
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}
.close-map-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2D7FF9;
  border-radius: 15px;
  color: white;
  text-align: center;
  padding: 0px 20px;
  width: 120px;
  height: 40px;
  bottom: 50px;
  left: 78%;
  cursor: pointer;
  @media (max-width: 767px) {
    left: calc(95% - 120px);
    bottom: 60px;
  }
}
</style>
